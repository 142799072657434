<template>
  <esmp-modal
    v-model="isShowedConfirm"
    class="modal-confirm"
    @on-ok="removeControl"
    @on-cancel="onCancel"
  >
    <template #header v-if="modalData">
      Удаление элемента:<br>{{ modalData.label }}
    </template>
    <template v-if="modalData">
      <div v-if="modalData.onlyAdminUsage || modalData.hasWorkFlow">
        Контрол имеет привязку к workflow/очередям, его удаление может привезти к ошибке в работе формы.<br>
        Всё равно удалить?
      </div>
      <div v-else-if="modalData.connectedFieldListIds.includes(modalData.techId)">
        Значения контрола имеют привязку к группе/группам, его удаление может привезти к ошибке в работе формы.<br>
        Всё равно удалить?
      </div>
      <div v-else>
        Вы уверены?
      </div>
    </template>
  </esmp-modal>
</template>

<script>
import Hub from '@/plugins/event-hub';

export default {
  name: 'ConfirmModal',
  data() {
    return {
      isShowedConfirm: false,
      modalData: null,
    };
  },
  methods: {
    removeControl() {
      Hub.$emit('delete-control', this.modalData.techId);
    },
    onCancel() {
      this.modalData = null;
    },
  },
  created() {
    Hub.$on('show-delete-control-confirm-modal', (data) => {
      this.modalData = data;
      this.isShowedConfirm = true;
    });
  },
  beforeDestroy() {
    Hub.$off('show-delete-control-confirm-modal');
    this.modalData = null;
  },
};
</script>
